<template>
  <v-container>
    <div v-if="loadingView">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>

      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <go-back-btn :routeToGo="routeToGo" />
          <PageHeader :title="title" class="mb-3" />
          <!-- Data table -->
          <v-card>
            <v-container>
              <v-row>
                <v-col
                  col="9"
                  class="pt-0 pb-0"
                  align="start"
                  align-self="center"
                >
                  <v-btn-toggle dense v-model="text" color="primary" group>
                    <v-btn value="DATOS GENERALES" @click="switchTab(1)">
                      Datos generales
                    </v-btn>
                    <v-btn @click="switchTab(2)"> Conexiones </v-btn>
                    <v-btn @click="switchTab(5)"> Versiones </v-btn>
                    <v-btn @click="switchTab(3)"> Contactos </v-btn>
                    <v-btn @click="switchTab(4)"> Notas </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <section v-if="seeDatosGrales" class="pt-10">
                <v-row>
                  <v-col cols="1" class="py-0">
                    <v-text-field
                      v-model="codigoEquipo"
                      disabled
                      class="center-input"
                      label="Equipo"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      v-model="integrantesEquipo"
                      disabled
                      label="Integrantes equipo"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      v-model="fechaLicencia"
                      label="Fecha licencia"
                      :append-icon="calendarIcon"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0 mt-n3">
                    <v-switch
                      class="ml-4"
                      v-model="licenciaPerpetua"
                      dense
                      outlined
                      disabled
                      label="Licencia perpetua"
                    >
                    </v-switch>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      v-model="fechaBaja"
                      label="Fecha baja"
                      :append-icon="calendarIcon"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      v-model="provinciaNombre"
                      label="Provincia"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <span class="pl-10 headline primary--text" v-if="soporte"
                      >(*) CON SOPORTE</span
                    >
                    <span class="pl-10 headline error--text" v-else
                      >(*) SIN SOPORTE</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="mt-n5 mb-n2">
                    <span class="pl-0 primary--text">Sitios</span>
                  </v-col>
                  <!-- Información sitios -->
                  <v-col cols="12" md="12" class="py-0 pb-2 pt-n3">
                    <v-data-table
                      class="elevation-2"
                      :headers="headersSitios"
                      :items="itemsSitios"
                      no-data-text="No hay datos disponibles para mostrar"
                      hide-default-footer
                    ></v-data-table>
                  </v-col>
                </v-row>
              </section>
              <section v-if="seeDatosConexion" class="pt-10">
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="mt-n3">
                    <span class="pl-0 primary--text">Conexiones</span>
                  </v-col>
                  <!-- Conexiones -->
                  <v-col cols="12" md="12" class="py-0 mt-n2">
                    <v-data-table
                      class="elevation-2"
                      :headers="headersConexiones"
                      :items="itemsConexiones"
                      no-data-text="No hay datos disponibles para mostrar"
                      hide-default-footer
                    >
                      <template v-slot:[`item.habilitado`]="{ item }">
                        <v-icon
                          v-if="item.habilitado"
                          small
                          color="primary"
                          class="d-flex justify-center"
                        >
                          {{ checkIcon }}
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="mt-2">
                    <span class="pl-0 primary--text">Servidores</span>
                  </v-col>
                  <!-- Servidores -->
                  <v-col cols="12" md="12" class="py-0 mt-n2">
                    <v-data-table
                      class="elevation-2"
                      :headers="headersServidores"
                      :items="itemsServidores"
                      no-data-text="No hay datos disponibles para mostrar"
                      hide-default-footer
                    >
                    </v-data-table>
                  </v-col>
                  <!-- Obvservaciones -->
                  <v-col cols="12" class="py-0 pt-4">
                    <v-textarea
                      v-model="obvservaciones"
                      label="Obvservaciones"
                      outlined
                      dense
                      disabled
                      rows="7"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <div>
                  <v-row>
                    <v-col cols="6" class="px-0">
                      <UserBasicsYAvan
                        :fromSeeAllDataCliente="true"
                        :clienteIdProp="this.$route.params.clientItem.clienteId"
                        :userAdvancedProp="false"
                      >
                      </UserBasicsYAvan>
                    </v-col>
                    <v-col cols="6" class="px-0">
                      <UserBasicsYAvan
                        :fromSeeAllDataCliente="true"
                        :clienteIdProp="this.$route.params.clientItem.clienteId"
                        :userAdvancedProp="true"
                      >
                      </UserBasicsYAvan>
                    </v-col>
                  </v-row>
                </div>
              </section>
              <!-- versiones -->
              <section v-if="seeVersiones">
                <v-col cols="3" class="py-0 pt-5 mb-n7">
                  <v-autocomplete
                    v-model="sistemaSelected"
                    :items="itemsSistemas"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    return-object
                    dense
                    autocomplete="off"
                    label="Sistemas"
                    @change="setDatosCliente"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0">
                  <v-data-table
                    :loading="loadingVersiones"
                    class="elevation-2 mt-6"
                    :headers="headersVersiones"
                    :items="itemsVersiones"
                    no-data-text="No hay datos disponibles para mostrar"
                  ></v-data-table>
                </v-col>
              </section>
              <section v-if="seeContacto">
                <v-data-table
                  class="elevation-2 mt-6"
                  :headers="headersContacto"
                  :items="itemsContacto"
                  item-key="contactoId"
                  no-data-text="No hay datos disponibles para mostrar"
                  hide-default-footer
                >
                </v-data-table>
              </section>
              <section v-if="seeNotas">
                <v-data-table
                  class="elevation-2 mt-6"
                  :headers="headerNotas"
                  :items="itemsNotas"
                  item-key="notaId"
                  no-data-text="No hay datos disponibles para mostrar"
                  hide-default-footer
                >
                </v-data-table>
              </section>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import UserBasicsYAvan from "@/views/UsersBasicsYAvan.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn, UserBasicsYAvan },
  name: "SeeAllDataClient",
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.$route.params.parentOptionCode
    );
    const sistemas = await this.sistemasTaxonomy();
    this.itemsSistemas = sistemas;
    this.setDatosCliente();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
  },
  data() {
    return {
      routeToGo: "/clientes",
      checkIcon: enums.icons.CHECK_OUTLINE,
      calendarIcon: enums.icons.CALENDAR_ICON,
      title: `Datos cliente: ${this.$route.params.clientItem.clienteId} - ${this.$route.params.clientItem.clienteNombre}`,
      text: "DATOS GENERALES",
      seeDatosGrales: true,
      seeDatosConexion: false,
      seeNotas: false,
      seeContacto: false,
      seeVersiones: false,
      licenciaPerpetua: false,
      integrantesEquipo: null,
      codigoEquipo: null,
      fechaLicencia: null,
      fechaBaja: null,
      provinciaNombre: null,
      soporte: false,
      itemsSitios: [],
      loadingVersiones: false,
      itemsServidores: [],
      obvservaciones: null,
      itemsVersiones: [],
      itemsConexiones: [],
      itemsNotas: [],
      itemsSistemas: [],
      sistemaSelected: null,
      loadingView: true,
      itemsContacto: [],
      headersVersiones: [
        {
          text: "Fecha actualización",
          sortable: false,
          value: "fechaActualizacion",
        },
        { text: "Sistema", sortable: false, value: "sistema" },
        { text: "Versión", sortable: false, value: "versionNombre" },
        { text: "Fecha versión", sortable: false, value: "fechaVersion" },
        { text: "Usuario", sortable: false, value: "usuario" },
      ],
      headersSitios: [
        { text: "Nombre sitio", sortable: false, value: "sitioNombre" },
        { text: "Descripción", sortable: false, value: "descripcion" },
        { text: "Sistema", sortable: false, value: "sistemaNombre" },
      ],
      headersServidores: [
        {
          text: "Tipo de servidor",
          sortable: false,
          value: "servidorNombre",
          align: "left",
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
          align: "left",
        },
        {
          text: "Usuario alta",
          sortable: false,
          value: "usuAlta",
          align: "left",
        },
        {
          text: "Usuario modificación",
          sortable: false,
          value: "usuModi",
          align: "left",
        },
        {
          text: "Fecha alta",
          sortable: false,
          value: "fecAltaToShow",
          align: "left",
        },
        {
          text: "Fecha modificación",
          sortable: false,
          value: "fecModiToShow",
          align: "left",
        },
      ],
      headerNotas: [
        {
          text: "Tipo de nota",
          sortable: false,
          value: "tipoNota",
        },
        {
          text: "Titulo",
          sortable: false,
          value: "titulo",
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Usuario",
          sortable: false,
          value: "usuario",
        },
      ],
      headersContacto: [
        {
          text: "Nombre",
          sortable: false,
          value: "contactoNombre",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Telefono",
          sortable: false,
          value: "telefono",
        },
        {
          text: "Descripcion",
          sortable: false,
          value: "descripcion",
        },
      ],
      headersConexiones: [
        {
          text: "Conexión",
          sortable: false,
          value: "nombreConexion",
          align: "left",
        },
        {
          text: "IP o url",
          sortable: false,
          value: "datoIp",
          align: "left",
        },
        {
          text: "Usuario",
          sortable: false,
          value: "usuario",
          align: "left",
        },
        {
          text: "Dominio",
          sortable: false,
          value: "dominio",
          align: "left",
        },
        {
          text: "Puerto",
          sortable: false,
          value: "puerto",
          align: "left",
        },
        {
          text: "Activo",
          align: "center",
          sortable: false,
          value: "habilitado",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getAllClientData: "clientesData/getAllClientData",
      sistemasTaxonomy: "clientesData/sistemasTaxonomy",
    }),
    switchTab(num) {
      switch (num) {
        case 1:
          this.seeDatosGrales = true;
          this.seeDatosConexion = false;
          this.seeContacto = false;
          this.seeNotas = false;
          this.seeVersiones = false;

          break;
        case 2:
          this.seeDatosConexion = true;
          this.seeDatosGrales = false;
          this.seeContacto = false;
          this.seeNotas = false;
          this.seeVersiones = false;

          break;
        case 3:
          this.seeContacto = true;
          this.seeDatosConexion = false;
          this.seeDatosGrales = false;
          this.seeNotas = false;
          this.seeVersiones = false;

          break;
        case 4:
          this.seeContacto = false;
          this.seeDatosConexion = false;
          this.seeDatosGrales = false;
          this.seeNotas = true;
          this.seeVersiones = false;
          break;
        case 5:
          this.seeContacto = false;
          this.seeDatosConexion = false;
          this.seeDatosGrales = false;
          this.seeNotas = false;
          this.seeVersiones = true;
          break;
      }
    },
    async setDatosCliente() {
      this.loadingView = true;
      this.loadingVersiones = true;
      const params = {
        clienteId: this.$route.params.clientItem.clienteId,
        sistemaId: this.sistemaSelected == null ? 0 : this.sistemaSelected.id,
      };
      try {
        const res = await this.getAllClientData(params);
        this.itemsSitios = res.infoSitios;
        this.codigoEquipo = res.codigoEquipo;
        this.integrantesEquipo = this.$route.params.clientItem.equipo;
        this.fechaLicencia = res.fechaCorte;
        this.fechaBaja = res.fechaBaja;
        this.provinciaNombre = res.provNom;
        this.soporte = res.soporte;
        this.itemsServidores = res.servidores;
        this.itemsConexiones = res.conexiones;
        this.obvservaciones = res.obvservaciones;
        this.itemsVersiones = res.version;
        this.itemsContacto = res.contacto;
        this.itemsNotas = res.notas;
        this.licenciaPerpetua = res.licenciaPerpetua;
      } catch (error) {
        console.error(error);
      }
      this.loadingView = false;
      this.loadingVersiones = false;
    },
  },
};
</script>

<style scoped>
.label {
  opacity: 0.7;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
::v-deep .theme--light.v-input--is-disabled input {
  color: rgb(56, 56, 56);
}
::v-deep .theme--light.v-input--is-disabled label {
  color: rgb(56, 56, 56);
}
::v-deep .theme--light.v-input--is-disabled switch {
  color: rgb(56, 56, 56);
}
.center-input >>> input {
  text-align: center;
}
</style>
